import {isNullOrUndefined} from "@kubric/utils";
import {graphql} from "gatsby";
import {useFlags} from "gatsby-plugin-launchdarkly";
import React from "react";

import MMLastSection from "../components/AboveFooterSection";
import Layout from "../components/Layout";
import StructuredData, {StructuredDataType} from "../components/StructuredData";
import MasonTrustSection from "../components/pages/mason/MasonTrustSection";
import MMGrowth from "../components/pages/modemagic/Growth";
import MMHero from "../components/pages/modemagic/Hero";
import MMReasons from "../components/pages/modemagic/MMReasons";
import MMMidSection from "../components/pages/modemagic/MMV3MidSection";
import MMSection2 from "../components/pages/modemagic/MMV3Section2";
import MMSection3 from "../components/pages/modemagic/MMV3Section3";
import MMVideoSection from "../components/pages/modemagic/MMV3VideoSection";
import Quotes from "../components/pages/modemagic/Quotes";
import ROILinkSection from "../components/pages/modemagic/ROILinkSection";
import {SocialProofBanner} from "../components/pages/modemagic/SocialProofBanner";
import MMStickyCTA from "../components/pages/modemagic/StickyCTA";
import {useMediaQuery} from "../hooks/useMediaQuery";
import "./styles.scss";

const ModeMagic3 = ({data: cmsData}) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {
    hero = {},
    store,
    growth = [],
    feat,
    quotes,
    reasons,
    midSection,
    lastSection,
    video,
    videoHeadline,
  } = cmsData.allSanityModemagicPage.nodes[0] ?? {};
  const masonData = cmsData.allSanityHomepage.nodes[0];

  const purpleSectionRef = React.useRef(undefined);
  const [controlGrowth, variantGrowth] = growth;
  let {homepageGrowth = growth[0]?.variant} = useFlags();

  // If only one version is being sent via sanity, then show that to all users
  if (growth.length === 1) {
    homepageGrowth = growth[0].variant;
  }

  // to show alternate design when cta is over purple section
  const [alternateCta, setAlternateCta] = React.useState(false);

  // alternate display for cta when scrolled over purple section
  React.useEffect(() => {
    if (purpleSectionRef?.current) {
      const {top, bottom} = purpleSectionRef?.current.getBoundingClientRect();
      const screenHeight = document.documentElement.clientHeight;
      setAlternateCta(top + 34 < screenHeight && bottom + 80 >= screenHeight);
    }
  }, []);
  React.useEffect(() => {
    const updateScrollPercentage = () => {
      if (purpleSectionRef?.current) {
        const {top, bottom} = purpleSectionRef?.current.getBoundingClientRect();
        const screenHeight = document.documentElement.clientHeight;
        setAlternateCta(top + 34 < screenHeight && bottom + 80 >= screenHeight);
      }
    };

    window.addEventListener("scroll", updateScrollPercentage);
    return () => window.removeEventListener("scroll", updateScrollPercentage);
  }, [purpleSectionRef?.current]);

  return (
    <Layout type='modemagic' seoData={seoData} mmv3>
      <StructuredData
        type={StructuredDataType.PRODUCT}
        data={{
          name: "ModeMagic",
          key: "modemagic",
        }}
      />
      <div className='mobileSocialProofHomepage'>
        {isMobile && <SocialProofBanner data={hero.socialProofHomepage} />}
      </div>
      <MMHero data={hero} v3 />
      {!isMobile && (
        <div className='full-bleed homepageDesktopBanner'>
          <SocialProofBanner
            data={[...hero.socialProofHomepage, ...hero.socialProofHomepage]}
          />
        </div>
      )}
      <MMGrowth
        isMobile={isMobile}
        data={
          homepageGrowth === controlGrowth?.variant
            ? controlGrowth
            : variantGrowth
        }
        v3
      />
      <Quotes isMobile={isMobile} data={quotes} />
      {video?.show && (
        <MMVideoSection videoHeadline={videoHeadline} data={video?.video} />
      )}
      <MMSection2 data={feat} />
      <MMMidSection
        data={midSection}
        isMobile={isMobile}
        sectionRef={purpleSectionRef}
      />
      <MMReasons data={reasons} isMobile={isMobile} />
      <ROILinkSection />
      <MMSection3 data={store} isMobile={isMobile} />
      {!isMobile && <MasonTrustSection cmsRef={masonData} hideDesignElement />}
      <MMLastSection data={lastSection} isMobile={isMobile} />
      {isMobile && (
        <MMStickyCTA
          data={hero.cta}
          alternateCta={alternateCta}
          mobileCTAText={hero.mobileAuditCTA?.text}
          mobileCTAUrl={hero.mobileAuditCTA?.url}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "home"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityHomepage {
      nodes {
        logos {
          logo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          name
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        hero {
          _rawHeading
          _rawDescription
          _rawMobileDescription
          cta {
            text
            responseText
            action
            url
            mobileRedirectUrl
          }
          _rawAuditCtaText
          auditCTA {
            url
          }
          mobileAuditCTA {
            text
            url
          }
          socialProofHomepage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          belowCta
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          mobileImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          mobileAlt
          video {
            type
            showThumbnail
            ytId
            file {
              asset {
                url
              }
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
          }
          show
        }
        videoHeadline
        video {
          show
          video {
            asset {
              url
            }
          }
        }
        feat {
          _rawHeading
          _rawDescription
          cards {
            _key
            _rawHeading
            subHeading
            description
            image {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            hoveredImage {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
          }
        }
        midSection {
          _rawText
        }
        store {
          _rawHeading
          _rawDescription
          show
          ctaUrl
          ctaText
          gif {
            image {
              asset {
                gatsbyImageData
                url
              }
            }
            alt
          }
          items {
            _key
            heading
            description
            icon {
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              alt
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
        growth {
          variant
          heading
          mobileHeading
          _rawDescription
          _rawMobileDescription
          cta {
            text
            responseText
            action
            url
          }
          growthPower {
            cta {
              text
              url
            }
            featurePageReference {
              ctaText
              page {
                ... on SanityFeatureDetailsPage {
                  slug {
                    current
                  }
                }
                ... on SanityHubPage {
                  slug {
                    current
                  }
                }
              }
            }
            feature {
              heading
              _rawDescription
            }
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
            mobileImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            mobileAlt
            tabName
          }
        }
        quotes {
          author
          designation
          cta {
            text
            url
            idAttribute
          }
          image {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
          _rawQuote
        }
        reasons {
          _rawHeading
          cards {
            _key
            heading
            description
            image {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            hoveredImage {
              alt
              image {
                asset {
                  gatsbyImageData
                  url
                }
              }
            }
            ctaText
            ctaUrl
          }
        }
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          ctaUrl
          placeholder
        }
      }
    }
  }
`;

export default ModeMagic3;
