import React from "react";

export const Check = ({
  height = 25,
  width = 24,
  className,
  color = "black",
}) => {
  return (
    <svg
      color={color}
      width={width}
      height={height}
      fill='none'
      viewBox='0 0 24 25'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M20 6.07568L9 17.0757L4 12.0757'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
