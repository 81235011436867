import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React, {useState} from "react";
import Slider from "react-slick";

import Arrow from "../../../../assets/Arrow.svg";
import SanityRichText from "../../../commons/SanityRichText";
import {Check} from "../../../icons/Check";
import {RightArrow} from "../../../icons/RightArrow";
import "./styles.scss";

const MMGrowth = ({data, isMobile, v3}) => {
  const {
    growthPower: powersData = [],
    heading,
    mobileHeading,
    _rawDescription,
    _rawMobileDescription,
    cta: growthCta,
  } = data ?? {};
  const [selectedIndex, selectIndex] = useState(0);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    touchMove: true,
    className: "powerSlider",
    slidesToScroll: 1,
    variableWidth: true,
    slidesToShow: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    afterChange: (idx) => {
      selectIndex(idx);
    },
  };

  const powers = powersData.map(
    (
      {
        _rawText,
        action,
        cta,
        heading,
        tabName,
        image: {asset: {gatsbyImageData: image} = {}} = {},
        alt,
        mobileImage,
        mobileAlt,
        review,
        feature,
        featurePageReference,
      },
      idx
    ) => ({
      description: _rawText,
      image,
      alt,
      mobileAlt,
      mobileImage,
      action,
      index: idx,
      cta,
      tabName,
      heading,
      review,
      feature,
      featurePageReference,
    })
  );

  const selectedPower = powers[selectedIndex] ?? {};

  const features = selectedPower?.feature ?? [];

  return (
    <div
      id='power'
      className={`power-wrap-growth full-bleed layouted my-8 pb-12 px-4 md:px-0${
        v3 ? " v3" : ""
      }`}
    >
      <h2 className='growthHeading'>{isMobile ? mobileHeading : heading}</h2>
      <div className='growthDescription'>
        <SanityRichText
          blocks={isMobile ? _rawMobileDescription : _rawDescription}
          className='automationDescription'
        />
      </div>
      <div className='power-content w-full flex flex-col items-start mt-6'>
        <>
          <div className='powerNavigationBar'>
            {powers.map((power, idx) => (
              <p
                onClick={() => selectIndex(idx)}
                className={`tabName p-2 ${
                  selectedIndex === idx ? "selected" : ""
                }`}
              >
                {power.tabName}
              </p>
            ))}
          </div>
          <div className='flex flex-row w-full items-start growthMainContent'>
            <div className='growthPowerContent'>
              {features.map((feature, idx) => {
                return (
                  <div className='flex items-start growthContent'>
                    {idx !== 0 && (
                      <Check
                        color='white'
                        className='checkbox'
                        height={25}
                        width={25}
                      />
                    )}
                    <div className={idx !== 0 && "growthPowerContainer"}>
                      <h2
                        className={
                          idx !== 0 ? "smallHeading" : "growthPowerHeading"
                        }
                      >
                        {feature.heading}
                      </h2>
                      <div
                        className={
                          idx !== 0
                            ? "smallDescription"
                            : "growthPowerDescription mb-2"
                        }
                      >
                        <SanityRichText
                          blocks={feature._rawDescription}
                          renderContainerOnSingleChild
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <GatsbyImage
              alt={selectedPower.alt}
              className='powerImage'
              image={selectedPower.image}
            />
          </div>
          <div className='flex items-center gap-1'>
            <OutboundLink
              href={powers[selectedIndex]?.cta?.url}
              className='withoutBorder growthCTA'
              rel='noopener noreferrer'
              target='_blank'
              id='add-mm-app-growthcta'
            >
              {powers[selectedIndex]?.cta?.text}
            </OutboundLink>
            <RightArrow className='arrowIcon' />
          </div>
        </>
      </div>
    </div>
  );
};

export default MMGrowth;
