import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import "../../../../pages/styles.scss";
import SanityRichText from "../../../commons/SanityRichText";
import {RightArrow} from "../../../icons/RightArrow";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const Quotes = ({isMobile, data}) => {
  const {_rawQuote, author, designation, cta, image} = data ?? {};

  return (
    <section className={styler.get("quoteWrap", "full-bleed")}>
      <div className={styler.get("quoteContainer")}>
        <GatsbyImage
          className={styler.get("image")}
          image={image?.image?.asset?.gatsbyImageData}
          alt={image?.alt || "quote image"}
          objectFit='cover'
        />
        <div className={styler.get("quote")}>
          <SanityRichText blocks={_rawQuote} renderContainerOnSingleChild />
          <div className={styler.get("cite")}>
            <div className={styler.get("author")}>{author}</div>
            <div className={styler.get("designation")}>{designation}</div>
            <div className={styler.get("cta")}>
              <OutboundLink
                href={cta?.url}
                id={cta?.idAttribute}
                target='_blank'
                referrerPolicy='no-referrer'
                rel='noopener noreferrer'
              >
                {cta?.text}
              </OutboundLink>
              <RightArrow className={styler.get("arrowIcon")} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quotes;
