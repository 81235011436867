import React from "react";

export const RightArrow = ({height = 25, width = 24, className}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      fill='none'
      viewBox='0 0 25 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 12H19.5'
        stroke='#5F6FFF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 5L19.5 12L12.5 19'
        stroke='#5F6FFF'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
